<template>
  <div class="vasion-white-background">
    <Loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-show="emailSettingsMode" class="settings-div">
      <div class="vasion-page-title top-div">
        Email Documents
      </div>
      <div class="settings-area-div">
        <table>
          <tr>
            <td colspan="2">
              <div class="download-options-checkboxes">
                <div class="checkbox-send-as-attachments">
                  <VasionCheckbox
                    id="chk-send-as-attachments"
                    name="chk-send-as-attachments"
                    :checked="sendAsAttachments"
                    @change="toggleCheckbox('sendAsAttachments');"
                  >
                    Send Document as Attachments
                  </VasionCheckbox>
                </div>
                <div class="checkbox-burn-annotations">
                  <VasionCheckbox
                    v-show="sendAsAttachments && supportBurnAnnotations"
                    id="chk-burn-annotations"
                    name="chk-burn-annotations"
                    :checked="burnAnnotations"
                    @change="toggleCheckbox('burnAnnotations');"
                  >
                    Burn Annotations
                  </VasionCheckbox>
                </div>
                <div class="checkbox-include-sig-doc-history">
                  <VasionCheckbox
                    v-show="sendAsAttachments && supportIncludeHistory"
                    id="chk-include-sig-doc-history"
                    name="chk-include-sig-doc-history"
                    :checked="includeSigDocHistory"
                    @change="toggleCheckbox('includeSigDocHistory');"
                  >
                    Include Signature Doc History
                  </VasionCheckbox>
                </div>
              </div>
            </td>
          </tr>
          <tr v-show="sendAsAttachments && supportBurnAnnotations && !containsMultipleDocuments">
            <td class="settings-column">
              <VasionInput
                id="send-pages-text-box"
                v-model="sendPages"
                title="SEND PAGES"
                name="send-pages-text-box"
                placeholder=""
                inputType="top-white"
                class="bottom-margin"
              />
            </td>
            <td class="settings-column" />
          </tr><tr /><tr v-show="!sendAsAttachments">
            <td class="settings-column">
              <VasionInput
                id="max-views"
                v-model="maxViews"
                class="input-style"
                inputType="top-white"
                type="number"
                min="0"
                oninput="this.value = Math.abs(this.value)"
                width="100%"
                title="MAX VIEWS"
                name="max-views"
              />
            </td>
            <td class="settings-column">
              <VasionInput
                id="max-downloads"
                v-model="maxDownloads"
                class="input-style"
                inputType="top-white"
                type="number"
                min="0"
                oninput="this.value = Math.abs(this.value)"
                width="100%"
                title="MAX DOWNLOADS"
                name="max-downloads"
              />
            </td>
          </tr>
          <tr v-show="!sendAsAttachments">
            <td class="settings-column">
              <VasionInput
                id="expiration-hours"
                v-model="expirationHours"
                class="input-style"
                inputType="top-white"
                type="number"
                min="1"
                oninput="this.value = Math.abs(this.value)"
                width="100%"
                title="EXPIRATION HOURS"
                name="expiration-hours"
              />
            </td>
            <td class="settings-column">
              <VasionCheckbox
                id="chk-default-link-user"
                class="check-top-margin"
                name="chk-default-link-user"
                :checked="linkDefaultUser"
                @change="toggleCheckbox('linkDefaultUser');"
              >
                Recipient Login as Default Link User
              </VasionCheckbox>
            </td>
          </tr>
        </table>
      </div>
      <div class="settings-nav bottom-div align-right">
        <VasionButton id="button-settings-cancel" :classProp="'secondary'" @vasionButtonClicked="cancelClick()">
          Cancel
        </VasionButton>
        <VasionButton id="button-continue" :classProp="'primary'" @vasionButtonClicked="continueClick()">
          Continue
        </VasionButton>
      </div>
    </div>
    <div v-show="!emailSettingsMode" class="compose-div">
      <div class="vasion-page-title top-div">
        Email Documents
      </div>
      <div class="main-area-div">
        <VasionInput
          id="to-text-box"
          v-model="to"
          title="To:"
          name="to-text-box"
          placeholder=""
          inputType="top-white"
          class="bottom-margin"
        />

        <VasionInput
          id="cc-text-box"
          v-model="cc"
          title="Cc:"
          name="cc-text-box"
          placeholder=""
          inputType="top-white"
          class="bottom-margin"
        />

        <VasionInput
          id="bcc-text-box"
          v-model="bcc"
          title="Bcc:"
          name="bcc-text-box"
          placeholder=""
          inputType="top-white"
          class="bottom-margin"
        />

        <VasionInput
          id="subject-text-box"
          v-model="subject"
          title="Subject:"
          name="subject-text-box"
          placeholder=""
          inputType="top-white"
          class="bottom-margin"
        />

        <textarea
          v-model="body"
          class="vasion-textarea"
          placeholder="Enter text..."
          rows="10"
        />
      </div>
      <div class="compose-nav bottom-div">
        <table>
          <tr>
            <td>
              <VasionButton id="button-back" :classProp="'secondary'" @vasionButtonClicked="backClick()">
                Back
              </VasionButton>
            </td>
            <td class="align-right">
              <VasionButton id="button-cancel" :classProp="'secondary'" @vasionButtonClicked="cancelClick()">
                Cancel
              </VasionButton>
              <VasionButton
                id="button-send"
                :classProp="'primary'"
                :isDisabled="!canSend"
                @vasionButtonClicked="sendClick()"
              >
                Send
              </VasionButton>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'DocumentEmail',
  components: {
    Loading,
  },
  props: {
    documentIDString: {
      type: String,
      required: true,
    },
    isInDataView: {
      type: Boolean,
      required: false,
    },
    supportBurnAnnotations: {
      type: Boolean,
      required: false,
      default: true,
    },
    supportIncludeHistory: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      bcc: '',
      body: '',
      burnAnnotations: false,
      cc: '',
      emailSettingsMode: true,
      expirationHours: 24,
      fullPage: true,
      guids: [],
      includeSigDocHistory: false,
      isLoading: false,
      linkDefaultUser: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      maxDownloads: 0,
      maxViews: 0,
      sendAsAttachments: true,
      sendPages: '',
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      subject: '',
      to: '',
    }
  },
  computed: {
    canSend() { return this.to !== '' && this.subject !== '' && this.body !== '' },
    containsMultipleDocuments() { return this.documentIDString.includes(',') },
  },
  methods: {
    backClick() {
      this.emailSettingsMode = true
    },
    cancelClick() {
      this.$emit('close')
    },
    async continueClick() {
      if (!this.sendAsAttachments) {
        this.isLoading = true

        if (this.guids.length === 0) {
          await this.setBodyWithEmailLinks()
        } else {
          await this.updateCurrentGuidSettings()
        }

        this.isLoading = false
      }

      this.emailSettingsMode = false
    },
    async sendClick() {
      this.isLoading = true
      let dispatchString = ''
      if (this.isInDataView && this.sendAsAttachments) {
        dispatchString = 'document/emailAttributeForm'
      } else if (this.containsMultipleDocuments) {
        dispatchString = 'document/emailMultiDocument'
      } else {
        dispatchString = 'document/emailSingleDocument'
      }

      const payload = {
        DocumentIdString: this.documentIDString,
        To: this.to,
        CC: this.cc,
        BCC: this.bcc,
        Subject: this.subject,
        Body: this.body,
        BurnAnnotations: this.sendAsAttachments && this.supportBurnAnnotations && this.burnAnnotations,
        IncludeHistory: this.sendAsAttachments && this.supportIncludeHistory && this.includeSigDocHistory,
        SendAsAttachments: this.sendAsAttachments,
        SendPages: this.sendAsAttachments && this.supportBurnAnnotations && !this.containsMultipleDocuments ? this.sendPages : '',
        IncludeWatermark: false,
      }

      if (!this.containsMultipleDocuments) {
        payload.DocumentId = parseInt(this.documentIDString, 10)
      }

      // Only the emailSingleDocument endpoint supports the "SendPages" field
      const result = await this.$store.dispatch(dispatchString, payload)
      const name = this.to
      this.isLoading = true
      this.$emit('email-result', [result, name])
      this.$emit('close')
    },
    async setBodyWithEmailLinks() {
      const payload = {
        DocumentIdString: this.documentIDString,
        MaxViews: this.maxViews,
        MaxDownloads: this.maxDownloads,
        ExpirationHours: this.expirationHours,
        UseDefaultLinkUser: this.linkDefaultUser,
      }

      const result = await this.$store.dispatch('document/getEmailBodyWithLinks', payload)
      if (result) {
        this.body = `${this.body}${result.Value}`
        this.guids = result.guids
      }
    },
    toggleCheckbox(id) {
      this[id] = !this[id]
    },
    updateCurrentGuidSettings() {
      const payload = {
        guids: this.guids,
        MaxViews: this.maxViews,
        MaxDownloads: this.maxDownloads,
        ExpirationHours: this.expirationHours,
        UseDefaultLinkUser: this.linkDefaultUser,
      }

      this.$store.dispatch('document/updateDocumentGUIDs', payload)
    },
  },
}
</script>

<style lang="scss" scoped>
  .checkbox-send-as-attachments, .checkbox-burn-annotations, .checkbox-include-sig-doc-history {
    width: 217px;
  }

  .download-options-checkboxes {
    display: flex;
    flex-direction: row;
    margin: 24px 0px;
  }

  .vasion-textarea {
    resize: none;
  }

  .compose-nav {
    margin-top: 5px;
  }

  table {
    width: 100%;
  }

  .settings-column {
    width: 50%;
    padding-right: 5px;
    padding-top: 10px;
  }

  .check-top-margin {
    margin-top: 20px;
  }

  .bottom-margin {
    margin-bottom: 10px;
  }

  .settings-div {
    width: 650px;
    height: 350px;
    margin: 24px 24px 28px 24px;
  }

  .settings-area-div {
    width: 100%;
    height: 270px;
  }

 .compose-div {
    width: 928px;
    height: 580px;
    margin: 24px 24px 24px 24px;
  }

  .top-div {
    height: 50px;
  }

  .main-area-div {
    width: 100%;
    height: 490px;
  }

  .bottom-div {
    width: 100%;
    vertical-align: middle;
    line-height: 50px;
  }

  .align-right {
    text-align: right;
  }
</style>
